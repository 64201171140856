<template>
	<div class="content-wrapper">
		<div class="content-container">
			<div id="recommended-region-list">
				<table-tree class="table-hover table" :columns="columns" :rows="rows" :filter="filter" :start="start" :end="end" @filter-change="filterChanged" selectable style="overflow: auto;">
					<template slot="button" slot-scope="props">
						<div style="display:flex; justify-content: center;">
							<router-link :to="{ name: 'CreateRecommendedRegion', query: { uid: props.row.uid } }"><b-button target="_blank">수정</b-button></router-link>
							<b-button @click="deleteRegion(props.row.uid)" target="_blank">삭제</b-button>
						</div>
					</template>

					<template slot="depth" slot-scope="props">
						<span v-if="props.row._children.length > 0" style="color: #0091ff; font-size: 20px; font-weight:900; cursor:pointer;">{{ props.row.depth }}</span>
						<span v-else style="color: #0091ff; font-size: 20px; font-weight:900;">{{ props.row.depth }}</span>
					</template>

					<template slot="toggle-children-icon" slot-scope="props">
						<span style="cursor:pointer;position:relative; left:-0.3rem; top: -0.2rem;">[{{ props.expanded ? '-' : '+' }}]</span>
					</template>
				</table-tree>
                <div style="text-align:right;">
                    <router-link :to="{name : 'CreateRecommendedRegion'}"><b-button variant="outline-primary" size="lg"><span style="font-size: 1rem;">추가</span></b-button></router-link>
                </div>
			</div>
		</div>
	</div>
</template>

<script>
import TableTree from 'vue-ads-table-tree';

export default {
	name: 'RecommendedRegionList',

	components: {
		TableTree,
	},

	data() {
		let columns = [
			{
				property: 'depth',
				title: 'Depth',
				direction: null,
				filterable: true,
			},
			{
				property: 'seq',
				title: 'Seq',
				direction: true,
				filterable: true,
			},
			{
				property: 'ko',
				title: 'KO',
				direction: null,
				filterable: true,
			},
			{
				property: 'en',
				title: 'EN',
				direction: null,
				filterable: true,
			},
			{
				property: 'jp',
				title: 'JP',
				direction: null,
				filterable: true,
			},
			{
				property: 'cn',
				title: 'CN',
				direction: null,
				filterable: true,
			},
			{
				property: 'hk',
				title: 'HK',
				direction: null,
				filterable: true,
			},
			{
				property: 'button',
				title: ' ',
				direction: null,
				filterable: false,
			},
		];

		return {
			rows: [],
			columns,
			filter: '',
			start: 0,
			end: 5,
		};
	},
	async created() {
		await this.$store.dispatch('regions/list');
		this.dataStructuring();
	},
	methods: {
		filterChanged(filter) {
			this.filter = filter;
		},
		async deleteRegion(uid) {
			await this.$store.dispatch('regions/delete', { id: uid });
			await this.$store.dispatch('regions/list');
			this.dataStructuring();
		},
		async dataStructuring() {
			let items = this.$store.state.regions.items;
			Object.keys(items).forEach((o) => {
				let name = JSON.parse(items[o].name);
				items[o].ko = name.ko ? name.ko : '';
				items[o].en = name.en ? name.en : '';
				items[o].jp = name.jp ? name.jp : '';
				items[o].cn = name.cn ? name.cn : '';
				items[o].hk = name.hk ? name.hk : '';
				delete items[o].name;

				Object.keys(items).every((p) => {
					if (items[o].parent_id && items[o].parent_id == items[p].uid) {
						if (!items[p]._children) {
							items[p]._children = [];
						}
						items[p]._children.push(items[o]);
						delete items[o];
						return false;
					} else {
						return true;
					}
				});
			});

			Object.keys(items).forEach((o) => {
				if (items[o].depth > 1) delete items[o];
			});

			this.$data.rows = items.filter((item) => item);
		},
	},
};
</script>
